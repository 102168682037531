import Vue from "vue";
import Router from "vue-router";

// import Home from "@/views/HomeCoilView";
import About from "@/views/AboutCoilView";
import Interested from "@/views/InterestedCoilView";
import Testimonials from "@/views/TestimonialsCoilView";
import Presence from "@/views/PresenceCoilView";
import Publications from "@/views/PublicationsCoilView";
import Resources from "@/views/resources/IndexView";
import ResourcesArticles from "@/views/resources/ArticlesView";
import ResourcesBooks from "@/views/resources/BooksView";
import ResourcesInfographics from "@/views/resources/InfographicsView";
import ResourcesSome from "@/views/resources/SomeView";
import ResourcesTutorial from "@/views/resources/TutorialView";
import ResourcesWorkbook from "@/views/resources/WorkbookView";
import Events from "@/views/EventsCoilView";
import Comission from "@/views/ComissionCoilView";
import Registration from "@/views/RegistrationCoilView";
import JornadaCoil from "@/views/JornadaCoilView";

import Coilaborando from "@/views/CoilaborandoView";
import PreviosCoilaborando from "@/views/coilaborando/PreviosView";
import JunioCoilaborando from "@/views/coilaborando/2023/JunioView";
import JulioCoilaborando from "@/views/coilaborando/2023/JulioView";
import AgostoCoilaborando from "@/views/coilaborando/2023/AgostoView";
import SeptiembreCoilaborando from "@/views/coilaborando/2023/SeptiembreView";
import OctubreCoilaborando from "@/views/coilaborando/2023/OctubreView";
import DiciembreCoilaborando from "@/views/coilaborando/2023/DiciembreView";
import EneroCoilaborando from "@/views/coilaborando/2024/EneroView";
import FebreroCoilaborando from "@/views/coilaborando/2024/FebreroView";
import MarzoCoilaborando from "@/views/coilaborando/2024/MarzoView";
import AbrilCoilaborando from "@/views/coilaborando/2024/AbrilView";
import MayoCoilaborando from "@/views/coilaborando/2024/MayoView";

import Jornada2th from "@/views/Jornada2thView";

Vue.use(Router);

export default new Router({
  routes: [
    /*{
      path: "/",
      name: "home",
      component: Home,
    },*/
    {
      path: "/",
      name: "home",
      component: Jornada2th,
    },
    {
      path: "/acerca-de",
      name: "about-coil",
      component: About,
    },
    {
      path: "/interes",
      name: "interested",
      component: Interested,
    },
    {
      path: "/testimonios",
      name: "testimonials",
      component: Testimonials,
    },
    {
      path: "/presencia",
      name: "presence",
      component: Presence,
    },
    {
      path: "/publicaciones",
      name: "publications",
      component: Publications,
    },
    {
      path: "/recursos",
      name: "resources",
      component: Resources,
    },
    {
      path: "/recursos/articulos",
      name: "articles",
      component: ResourcesArticles,
    },
    {
      path: "/recursos/libros",
      name: "books",
      component: ResourcesBooks,
    },
    {
      path: "/recursos/infografias",
      name: "infographics",
      component: ResourcesInfographics,
    },
    {
      path: "/recursos/varios",
      name: "some",
      component: ResourcesSome,
    },
    {
      path: "/recursos/tutorial",
      name: "tutorial",
      component: ResourcesTutorial,
    },
    {
      path: "/recursos/workbook",
      name: "workbook",
      component: ResourcesWorkbook,
    },
    {
      path: "/eventos",
      name: "events",
      component: Events,
    },
    {
      path: "/consejeros",
      name: "comission",
      component: Comission,
    },
    {
      path: "/registro",
      name: "registration",
      component: Registration,
    },
    {
      path: "/jornada-coil",
      name: "jornadaCoil",
      component: JornadaCoil,
    },
    {
      path: "/2jornada",
      name: "2jornada",
      component: Jornada2th,
    },
    {
      path: "/coilaborando",
      name: "coilaborando",
      component: Coilaborando,
    },
    {
      path: "/coilaborando/previos",
      name: "previos",
      component: PreviosCoilaborando,
    },
    {
      path: "/coilaborando/previos/2023/junio",
      name: "2023-junio",
      component: JunioCoilaborando,
    },
    {
      path: "/coilaborando/previos/2023/julio",
      name: "2023-julio",
      component: JulioCoilaborando,
    },
    {
      path: "/coilaborando/previos/2023/agosto",
      name: "2023-agosto",
      component: AgostoCoilaborando,
    },
    {
      path: "/coilaborando/previos/2023/septiembre",
      name: "2023-septiembre",
      component: SeptiembreCoilaborando,
    },
    {
      path: "/coilaborando/previos/2023/octubre",
      name: "2023-octubre",
      component: OctubreCoilaborando,
    },
    {
      path: "/coilaborando/previos/2023/diciembre",
      name: "2023-diciembre",
      component: DiciembreCoilaborando,
    },
    {
      path: "/coilaborando/previos/2024/enero",
      name: "2024-enero",
      component: EneroCoilaborando,
    },
    {
      path: "/coilaborando/previos/2024/febrero",
      name: "2024-febrero",
      component: FebreroCoilaborando,
    },
    {
      path: "/coilaborando/previos/2024/marzo",
      name: "2024-marzo",
      component: MarzoCoilaborando,
    },
    {
      path: "/coilaborando/previos/2024/abril",
      name: "2024-abril",
      component: AbrilCoilaborando,
    },
    {
      path: "/coilaborando/previos/2024/mayo",
      name: "2024-mayo",
      component: MayoCoilaborando,
    },
  ],
});
